@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/AvenirNextLTPro-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}


.text-red{
    color: red;
}

.text-green{
    color: green;
}

.table>:not(caption)>*>*{
    padding: .8rem .5rem;
}

.card-icon-blue{
    background-color: #E8F0FE;
    padding: 1rem;
    height: 40px;
    width: 40px;
    color: #3380FD;
}
.card-icon-purple{
    background-color: #F8F5FF;
    padding: 1rem;
    height: 40px;
    width: 40px;
    color: #B59CFA;
}
.card-icon-red{
    background-color: #ffc5d4;
    padding: 1rem;
    height: 40px;
    width: 40px;
    color: #df183f;
}

.card-grey{
    background-color: #FAFAFA !important;
}

.small-summary-amt{font-weight: bold;font-size: 1.1rem}
.small-summary-head{font-size: 0.9rem;color: #8e8e8e
}

.pie-cart-container, .pie-cart-container canvas{height: 308px !important;}
